













































// $body-font-family: 'Open Sans', sans-serif;

.v-application {
  // font-family: $body-font-family !important;
  .v-btn {
    letter-spacing: normal;
    text-transform: none;
  }
}

#id {
  background: #221eeb;
}

.fade-transform-leave-active,
.fade-transform-enter-active {
  transition: all .5s;
}

.fade-transform-enter {
  opacity: 0;
  transform: translateX(-30px);
}

.fade-transform-leave-to {
  opacity: 0;
  transform: translateX(30px);
}


.container-popup {
  font-family: 'Open sans', sans-serif;
  .animated {
    animation-duration: .2s;
  }
  .swal2-popup {
    border-radius: 0;
  }
}

body.swal2-height-auto {
  padding-right: 0 !important;
}

.cadastro-usuario {
  .v-data-table__wrapper {
    table {
      min-width: auto;

      @media (max-width: 599px) {
        min-width: auto;
      }
    }
  }
}

.theme--light.v-card > .v-card__text,
.theme--light.v-card .v-card__subtitle {
  color: #000 !important;
}

.theme--light.v-label {
  color: #000 !important;
}

.theme--light.v-label--is-disabled {
  color: rgba(0, 0, 0, 0.38) !important;
}
